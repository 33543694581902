<template>
    <base-overlay key="'form'" @close="closeOverlay">
        <div class="section has-form">
            <contact-form v-if="form && Object.keys(form).length" :form="{...form, defaultErrorMessage:form_field_errMessage}"></contact-form>
        </div>
    </base-overlay>
</template>

<script>
    import actions from "../modules/fetchAxios";
    import store from "../store";
    import overlayMixin from "../mixins/overlayMixin";
    import BaseOverlay from "../components/baseOverlay";
    import ContactForm from "../components/contact-form";

    export default {
        name: "ContactUs",
        components: {ContactForm, BaseOverlay},
        mixins: [overlayMixin],
        props: {},
        computed: {
            form_id(){
                return store.form_id
            },
            form() {
                return store.form
            },
            form_field_errMessage(){
                return store.form_field_errMessage
            }
        },
        watch: {
            form_id(val) {
                if (val) {
                    console.log(val)
                    this.loadForm()
                }
            }
        },
        mounted() {
            if (this.form_id && (!this.form || !Object.keys(this.form).length)) {
                this.loadForm()
            }
        },
        methods: {
            async loadForm() {
                store.form = await actions.getForm(this.form_id)
            }
        }
    }
</script>

<style scoped>

</style>